import React from "react";

export const ErrorBlock = () => {
    return <div className="mainSuccesBlok">
        {/*<div className="mainSuccesBlokName">*/}
        {/*    <p>Müştəri, </p>*/}
        {/*    <p>Anar Məmmədov</p>*/}
        {/*</div>*/}
        <div className="mainSuccesBlokIcon error">
            <img src="/images/errorIcon.svg" alt=""/>
        </div>
        <h1>Uğursuz əməliyyat</h1>
        <p>Xahiş edirik, məlumatları yoxlayın.</p>
        {/*<div className="mainSuccesBlokButtons">*/}
        {/*    <a href="" className="mainSuccesBlokButton1">Ödəniş səhifəsinə qayıt</a>*/}
        {/*</div>*/}
    </div>
}