import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";

export function CountdownRedirect({Component, redirectUrl}) {
    const [countdown, setCountdown] = useState(5); // Set initial countdown time in seconds
    const {t} = useTranslation()

    useEffect(() => {
        if (countdown === 0) {
            window.location.href = redirectUrl; // URL of the website you want to redirect to
        }

        const timer = countdown > 0 && setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        // Cleanup the timer when the component is unmounted or when countdown changes
        return () => clearInterval(timer);
    }, [countdown]);

    return (
        <div>
            <Component/>
            <div className={"mainTimerBlok"}>
                <p>{countdown} {t('redirectingText')}</p>
            </div>
            <div className="mainSuccesBlokButtons">
                <a href={redirectUrl} className="mainSuccesBlokButton1">{t("backToPage")}</a>
            </div>
        </div>
    );
}