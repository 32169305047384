import React, {useState} from 'react';
import {useLoaderData, useActionData} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {NotFoundPage} from "../components/notFound.js";
import {DEFAULT_CURRENCY} from "../constants.js";
import valid from "card-validator";
import {submitPayout} from "../loaderAndActions/payout.js";
import {SuccessBlock} from "./success.js";
import {ErrorBlock} from "./error.js";
import {CountdownRedirect} from "./redirect.js";

function PayToCard({amount}) {
    const data = useLoaderData();
    const [loading, setLoading] = useState(false)
    const [payoutResult, setPayoutResult] = useState(null)
    const [redirectUrl, setRedirectUrl] = useState(null)
    const {t} = useTranslation();
    const [cardNumber, setCardNumber] = useState({formattedValue: ''});
    const validateCardNumber = (str) => {
        const numberValidation = valid.number(str);
        if (!numberValidation.isPotentiallyValid) {
            return {validateStatus: 'error', errorMsg: t("invalidCard")};
        }
        return {validateStatus: null, errorMsg: null,}
    };
    const cardNumberChange = (e) => {
        const {value} = e.target;

        const unMasked = value.split(' ').join('').trim()
        if (unMasked && !/^\d+$/.test(unMasked)) return
        const masked = [...unMasked].map((d, i) => (i) % 4 == 0 ? ' ' + d : d).join('').trim()

        setCardNumber(prev => ({
            ...prev,
            ...validateCardNumber(unMasked),
            value: unMasked,
            formattedValue: masked
        }));
    };
    const submit = async (_id, csrf) => {
        setLoading(true);
        try {
            const result = await submitPayout({number: cardNumber.value, _id, csrf});
            if (result?.redirectUrl) {
                setRedirectUrl(result?.redirectUrl);
            }
            setPayoutResult(!!result?.success)
        } catch (e) {
            console.log("ERROR submitPayout ", e)
            setPayoutResult(false)
        } finally {
            setLoading(false)
        }
    }
    if (payoutResult === true) {
        return redirectUrl ? <CountdownRedirect Component={SuccessBlock} redirectUrl={redirectUrl}/> : <SuccessBlock/>
    }
    if (payoutResult === false) {
        return redirectUrl ? <CountdownRedirect Component={ErrorBlock} redirectUrl={redirectUrl}/> : <ErrorBlock/>
    }
    if ((data.code === 0) && data.data) {
        const {amount, _id, name} = data.data;
        return <>
            <div className="paymentPageForm">
                <div className="mainInputBlok">
                    <label htmlFor="card">{t('cardNumber')}</label>
                    <input type="text" name='card_number'
                           value={cardNumber.formattedValue}
                           onChange={cardNumberChange}
                           maxLength={23} required
                           id="card" placeholder="0000 0000 0000 0000"/>
                    <img src="/images/paymentCardIcon2.svg" alt="" className="paymentCardIcon"/>
                </div>
                {cardNumber.errorMsg && <p className="errorText">{cardNumber.errorMsg}</p>}


            </div>
            <div className="paymentPageListBlok">
                <div className="paymentPageList">
                    <p>{t('amount')}:</p>
                    <p>{amount} {DEFAULT_CURRENCY}</p>
                </div>

                <div className="paymentPageList">
                    <p>{t('name')}:</p>
                    <p>{name}</p>
                </div>
                <div className="paymentPageList">
                    <p>{t('description')}:</p>
                    <p>{t('oct')}</p>
                </div>

            </div>
            <div className={`ld-over-full-inverse ${loading ? 'running' : ''}`}>
                <div className="ld ld-ring ld-cycle"></div>
            </div>
            <button
                className={`mainSuccesBlokButton2 ${(!!cardNumber.errorMsg || !cardNumber.value?.length || loading) ? 'disabled' : ''}`}
                onClick={() => {
                    submit(_id, data.csrf)
                }}
                disabled={!!cardNumber.errorMsg || !cardNumber.value?.length || loading}>{t('confirm')}</button>
            <p className="paymentPageBottomText">
                {t('confirmDesc')}
            </p>
        </>


    } else return <NotFoundPage custom={t('linkExpired')}/>
}

export default PayToCard