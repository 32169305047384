import {
    createBrowserRouter,
    RouterProvider,
    createRoutesFromElements, Route
} from "react-router-dom";
import PayToCard from "../pages/pay.js";
import {NotFoundPage} from "../components/notFound.js";
import {getPayoutRequest} from "../loaderAndActions/payout.js";
import CheckoutLayout from "../layouts/checkout.js";
import {ErrorBlock} from "../pages/error.js";
import {SuccessBlock} from "../pages/success.js";


let style = document.createElement('style');
document.head.appendChild(style);

function App() {

    //
    // const router = createBrowserRouter([
    //     {
    //         path: "/payout/:id",
    //         element: <CheckoutRoutes component={PayToCard}/>,
    //         loader: getPayoutRequest,
    //         errorElement: <CheckoutRoutes component={NotFoundPage}/>,
    //         action: submitPayout
    //     }])

    const router1 = createBrowserRouter(createRoutesFromElements(
        <Route element={<CheckoutLayout/>}>
            <Route path={"/payout/:id"} element={<PayToCard/>} loader={getPayoutRequest}/>
            <Route path={"/payout/error"} element={<ErrorBlock/>}/>
            <Route path={"/payout/success"} element={<SuccessBlock/>}/>
            <Route path="*" element={<NotFoundPage/>}/>
        </Route>
    ))

    return (
        <RouterProvider router={router1}/>
    );
}

export default App;
