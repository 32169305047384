import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const NotFoundPage = ({custom}) => {
    const {t} = useTranslation()
    return (
        <div className='container notFoundPage-container'>
            <img src='/img/broken-link.svg'/>
            <h4>{custom ? custom : t('pageNotExist')}</h4>
        </div>
    )
}