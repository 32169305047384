import React from 'react';
import {useTranslation} from "react-i18next";
import {Outlet} from 'react-router-dom'


function CheckoutLayout(props) {
    const {t} = useTranslation();
    return <section className="paymentPageContainer">
        <div className="paymentPageBlok">
            <div className="paymentPageHeader">
                <div className="paymentPageHeaderTimeBlok">
                    {/*    <div className="paymentPageHeaderTime" id="timer">03:00</div>*/}
                    {/*    <span>Qalan vaxt</span>*/}
                </div>
                <a href="" className="paymentPageHeaderLogo">
                    <img src="/images/headerLogo.svg" alt=""/>
                </a>
                {/*<div className="paymentPageHeaderLngBlok">*/}
                {/*    <button className="selectpopupButton" data-id="paymentPageLng">*/}
                {/*        <p>AZ</p>*/}
                {/*        <span className="selectpopupButtonIcon">*/}
                {/*			<img src="/images/selectpopupButtonIcon.svg" alt=""/>*/}
                {/*		</span>*/}
                {/*    </button>*/}
                {/*    <div className="selectpopupBody" id="paymentPageLng">*/}
                {/*        <div className="selectpopupList active">AZ</div>*/}
                {/*        <div className="selectpopupList">RU</div>*/}
                {/*        <div className="selectpopupList">EN</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <Outlet/>
            <div className="paymentPageFooter">
                <div className="paymentPageFooterLeft">
                    <span>{t('cibService')}</span>
                    <p>{t('contact')}</p>
                </div>
                <div className="paymentPageFooterRight">
                    <img src="images/paymentPageFooterIcon1.svg" alt=""/>
                    <img src="images/paymentPageFooterIcon2.svg" alt=""/>
                    <img src="images/paymentPageFooterIcon3.svg" alt=""/>
                </div>
            </div>

        </div>
    </section>

}

export default CheckoutLayout