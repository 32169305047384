import React from "react";
import {useTranslation} from "react-i18next";

export const SuccessBlock = () => {
    const {t} = useTranslation()

    return <div className="mainSuccesBlok">
        {/*<div className="mainSuccesBlokName">*/}
        {/*    <p>Müştəri, </p>*/}
        {/*    <p>Anar Məmmədov</p>*/}
        {/*</div>*/}
        <div className="mainSuccesBlokIcon succes">
            <img src="/images/succesIcon.svg" alt=""/>
        </div>
        <h1>{t('pendingMessage')}</h1>
        <p>{t('thanks')}</p>
        {/*<div className="mainSuccesBlokButtons">*/}
        {/*    <a href="" className="mainSuccesBlokButton1">Ödəniş səhifəsinə qayıt</a>*/}
        {/*    <button className="mainSuccesBlokButton2">Qəbzi paylaş</button>*/}
        {/*</div>*/}
    </div>

}